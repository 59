<template>
<div class="container" :style="globalStyle">
  <div class="info">
    <div>
      <p>ОБОРОТОВ: <span style="color: #08a88a">{{fullSpinsCount}}</span></p>
      <p style="display: none">radius: {{radius}}</p>
      <p style="display: none">distance: {{distance}}</p>
      <p style="display: none">remainingDistance: {{remainingDistance}}</p>
      <p style="display: none">spin: {{spin}}</p>
      <p style="display: none">speed: {{speed}}</p>
      <p>СКОРОСТЬ: <span style="color: #08a88a">{{sps}}</span> Об/Сек</p>
    </div>
  </div>

  <div class="spinner">
    <div class="inner">ХУЙ</div>
    <div class="outer">твоё мнение</div>
  </div>

  <div class="scroll-icon" v-bind:class="sps >= 0.02 ? 'hidden' : ''">
    <div style="height: 150px; overflow: visible">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 -20 500 500" width="500" height="500"
         preserveAspectRatio="xMidYMid meet"
         style="width: 100%; height: 100%; transform: translate3d(0px, 0px, 0px); content-visibility: visible;">
         <g>
            <g transform="matrix(1,0,0,1,0,0)" opacity="1" style="display: block;">
               <g transform="matrix(1,0,0,1,-35,-35)" opacity="1" style="display: block;">
                  <g opacity="1" transform="matrix(1,0,0,1,250,224.47999572753906)">
                     <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" class="mouse" stroke="#000" stroke-opacity="1" stroke-width="12" d="M0,158.63900756835938 C0,158.63900756835938 0,158.63900756835938 0,158.63900756835938 C-46.05400085449219,158.63900756835938 -82.64800262451172,119.94300079345703 -80.0790023803711,73.96099853515625 C-80.0790023803711,73.96099853515625 -73.41400146484375,-45.321998596191406 -73.41400146484375,-45.321998596191406 C-72.24099731445312,-66.31600189208984 -61.387001037597656,-84.5770034790039 -45.332000732421875,-95.86900329589844 C-34.53200149536133,-103.46499633789062 -21.37700080871582,-107.90799713134766 -7.23199987411499,-107.90799713134766 C-7.23199987411499,-107.90799713134766 7.232999801635742,-107.90799713134766 7.232999801635742,-107.90799713134766 C42.40399932861328,-107.90799713134766 71.4520034790039,-80.43800354003906 73.41400146484375,-45.321998596191406 C73.41400146484375,-45.321998596191406 80.0790023803711,73.96099853515625 80.0790023803711,73.96099853515625 C82.64800262451172,119.94300079345703 46.05400085449219,158.63900756835938 0,158.63900756835938z M0,-70.46700286865234 C0,-70.46700286865234 0,-107.91200256347656 0,-107.91200256347656 M75.53199768066406,-7.414000034332275 C45.44900131225586,20.969999313354492 -45.44900131225586,20.969999313354492 -75.53199768066406,-7.414000034332275 M0,13.869999885559082 C0,13.869999885559082 0,-13.432000160217285 0,-13.432000160217285"></path>
                  </g>
                  <g opacity="1" transform="matrix(1,0,0,1,250,182.56100463867188)">
                     <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" class="wheel" stroke="#000" stroke-opacity="1" stroke-width="12" d="M-0.12600000202655792,28.052000045776367 C-0.12600000202655792,28.052000045776367 0.12600000202655792,28.052000045776367 0.12600000202655792,28.052000045776367 C6.991000175476074,28.052000045776367 12.555999755859375,22.48699951171875 12.555999755859375,15.621999740600586 C12.555999755859375,15.621999740600586 12.555999755859375,-15.621999740600586 12.555999755859375,-15.621999740600586 C12.555999755859375,-22.48699951171875 6.991000175476074,-28.052000045776367 0.12600000202655792,-28.052000045776367 C0.12600000202655792,-28.052000045776367 -0.12600000202655792,-28.052000045776367 -0.12600000202655792,-28.052000045776367 C-6.991000175476074,-28.052000045776367 -12.555999755859375,-22.48699951171875 -12.555999755859375,-15.621999740600586 C-12.555999755859375,-15.621999740600586 -12.555999755859375,15.621999740600586 -12.555999755859375,15.621999740600586 C-12.555999755859375,22.48699951171875 -6.991000175476074,28.052000045776367 -0.12600000202655792,28.052000045776367z"></path>
                  </g>
               </g>
               <g transform="matrix(1,0,0,1,215,41.00299835205078)" opacity="1" style="display: block;">
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                     <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" class="arrow top" stroke="#000" stroke-opacity="1" stroke-width="12" d="M-25.617000579833984,12.807999610900879 C-25.617000579833984,12.807999610900879 0,-12.807999610900879 0,-12.807999610900879 C0,-12.807999610900879 25.617000579833984,12.807999610900879 25.617000579833984,12.807999610900879"></path>
                  </g>
               </g>
               <g transform="matrix(1,0,0,-1,215,388.99700927734375)" opacity="1" style="display: block;">
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                     <path stroke-linecap="round" stroke-linejoin="round" fill-opacity="0" class="arrow bot" stroke="#000" stroke-opacity="1" stroke-width="12" d="M-25.617000579833984,12.807999610900879 C-25.617000579833984,12.807999610900879 0,-12.807999610900879 0,-12.807999610900879 C0,-12.807999610900879 25.617000579833984,12.807999610900879 25.617000579833984,12.807999610900879"></path>
                  </g>
               </g>
            </g>
         </g>
      </svg>
    </div>
  </div>
</div>
</template>

<script>
import 'normalize.css'

export default {
  name: 'SPINNER',
  components: {},
  data() {
    return {
      //radius: 100,

      interval: 100,
      speedPerSpin: 1,
      distancePerSpin: 1,

      spin: 0,
      distance: 0,
      fullDistance: 0,
      speed: 0.00,
      fullSpinsCount: 0,
      remainingDistance: 4,
      sps: 0,

      orbit: [
          100, 200, 150,
      ]
    }
  },
  mounted() {
    setInterval(() => {
      this.distance += this.speed
      this.fullDistance += Math.abs(this.speed)

      this.speed *= 0.9
      if (this.speed < 0.1 && this.speed > -0.1) {
        this.speed = 0
      }

      this.fullSpinsCount = Math.abs(~~(this.distance / 360))

      this.sps = Math.abs(this.speed / 60).toFixed(2);
    }, this.interval)

    let touchPosY = 0;

    document.addEventListener('touchstart', (event) => {
      touchPosY = event.touches[0].pageY;
    });
    document.addEventListener('touchmove', (event) => {
      const touchDeltaY = touchPosY - event.touches[0].pageY;
      touchPosY = event.touches[0].pageY;

      let spin = Math.round(Math.round(touchDeltaY) / 4)
      //console.log(event)
      //console.log(spin)
      this.spin += spin
      this.speed += spin / 5
      this.remainingDistance += spin
    });
    // document.addEventListener('touchend', (event) => {

    //   const touchDeltaY = touchPosY - event.touches[0].pageY;

    //   alert(touchDeltaY)

    //   let spin = Math.round(Math.round(touchDeltaY) / 4)
    //   //console.log(event)
    //   //console.log(spin)
    //   this.spin += spin
    //   this.speed += spin / 10
    //   this.remainingDistance += spin
    // });

    document.addEventListener('wheel', (event) => {
      let spin = Math.round(Math.round(event.deltaY) / 4)
      console.log(event)
      //console.log(spin)
      this.spin += spin
      this.speed += spin / 10
      this.remainingDistance += spin
    })
  },
  computed: {
    radius() {
      return 90
    },
    globalStyle() {
      return {
        '--interval': this.interval + 'ms',
        '--radius': this.radius + 'px',
        '--distance': this.distance + 'deg',
      }
    },
  }
}
</script>

<style>

.scroll-icon svg path  {
  stroke: #121331;
}

.scroll-icon svg .arrow {
  stroke: #08a88a;
}

.scroll-icon svg .arrow.top {
  animation: 3s linear infinite 1.2s scroll-arrow-bounce;
}

.scroll-icon svg .arrow.bot {
  animation: 3s linear infinite 0s scroll-arrow-bounce;
}

.scroll-icon svg .wheel {
  stroke: #08a88a;
  animation: 4s linear infinite 0s scroll-wheel-bounce;
}

@keyframes scroll-arrow-bounce {
    0%,20%,50%,80%,100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-10px);
    }
}









html,body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  user-select: none; 


  font-weight: 600;
}

html,body * {
  font-family: Oswald, Arial, sans-serif;
  color: #121331;
}


.container {
  --interval: 0;
  --radius: 0;
  --distance: 0;
}

.container {
  font-size: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 3vw;

  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;

    overflow: hidden;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  align-content: center;
  flex: 1;
}

.outer {
  position: absolute;
  display: flex;
  justify-content: center;

  transition-property: transform, transform-origin;
  transition-duration: var(--interval);
  transition-timing-function: linear;

  transform-origin: 50% calc(50% + var(--radius));
  transform: translate(0, calc(-1 * var(--radius))) rotate(var(--distance));
}



.outer .line {
  position: absolute;
  width: 1px;
  height: calc(2rem + var(--radius));
  background-color: #000000;

  transition: height var(--interval) linear;
}



.info {
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;

  font-size: 1.2rem;
}

.scroll-icon {
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  transition: opacity 0.6s ease-in-out;
}

.scroll-icon.hidden {
  opacity: 0;
}
</style>
